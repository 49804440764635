/**
 * 分享文案API
 */

import request from '@/utils/request'

/**
 * 查询分享文案列表，用于分页查询
 * @param params
 * @returns {Promise<any>}
 */
 export function getPageList(params) {
  return request({
    url: 'share/shopShare/page',
    method: 'post',
    params
  })
}

/**
 * 查询分享文案列表，用于列表查询
 * @param params
 * @returns {Promise<any>}
 */
 export function getList(params) {
  return request({
    url: 'share/shopShare/list',
    method: 'post',
    params
  })
}

/**
 * 添加分享文案
 * @param params
 * @returns {Promise<any>}
 */
export function add(data) {
  return request({
    url: 'share/shopShare/add',
    method: 'post',
    data,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
* 查询分享文案详情
* @param params
* @returns {Promise<any>}
*/
export function get(id) {
  return request({
    url: `share/shopShare/${id}`,
    method: 'get'
  })
}

/**
* 删除分享文案
* @param params
* @returns {Promise<any>}
*/
export function del(id) {
  return request({
    url: `share/shopShare/${id}`,
    method: 'delete'
  })
}

/**
* 编辑店铺套餐礼包
* @param params
* @returns {Promise<any>}
*/
export function edit(data) {
  return request({
    url: `share/shopShare/edit`,
    method: 'post',
    data,
    headers: {'Content-Type': 'application/json'}
  })
}
