<template>
  <en-table-layout
    :tableData="pageData.data"
    :loading="loading"
  >
    <template slot="toolbar">
      <div class="col-auto">
        <el-button
          @click="handleAddShareVideo"
          type="primary"
          size="small"
        >
          添加
        </el-button>

      </div>
      <el-form-item
        label="类型"
        class="col-auto ml-3"
      >
        <el-select
          v-model="params.type"
          placeholder="请选择"
          clearable
          style="width:110px;"
          @change="search"
          size="small"
        >
          <el-option
            label="全部"
            value=""
          ></el-option>
          <el-option
            label="视频"
            :value="1"
          ></el-option>
          <!-- <el-option
                label="音频"
                :value="1"
              ></el-option> -->
          <el-option
            label="图文"
            :value="2"
          ></el-option>
        </el-select>
      </el-form-item>

      <div class="col"></div>

      <div class="col-auto">
        <el-input
          style="width: 200px;"
          size="small"
          placeholder="请输入名称"
          v-model="params.share_name"
          clearable
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
      </div>
      <div class="col-auto ml-2">
        <el-button
          @click="search"
          size="small"
          type="primary"
        >
          搜索
        </el-button>
      </div>
    </template>

    <template slot="table-columns">

      <el-table-column
        label="编号"
        width="50"
      >
        <template slot-scope="scope">
          {{ code(scope.$index, params) }}
        </template>
      </el-table-column>

      <el-table-column
        label="分享文案名称"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.share_name }}
        </template>
      </el-table-column>

      <el-table-column
        label="更新时间"
        prop="time"
      >
        <template slot-scope="scope">{{ scope.row.update_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>

      <el-table-column
        label="类型"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.type === 1 ? '视频':'图文' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEditCombo(scope.row)"
          >编辑
          </el-button>
        </template>
      </el-table-column>
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="pageData.page_no"
      :page-size="pageData.page_size"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
      :total="pageData.data_total"
    />
  </en-table-layout>

</template>

<script>
import * as API_ShareVideo from "@/api/shareVideo";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";

export default {
  name: "shareVideoList",
  components: {
    EnTableLayout,
  },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      showDialog: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        share_name: "",
        type: "",
      },

      /** 列表分页数据 */
      pageData: {},
    };
  },
  created() {},
  activated() {
    this.GET_ShareVideoList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted() {
    this.GET_ShareVideoList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  methods: {
    /**
     * 添加分享文案
     */
    handleAddShareVideo() {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "shareVideoEdit",
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_ShareVideoList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_ShareVideoList();
    },

    search() {
      this.params.page_no = 1;
      this.GET_ShareVideoList();
    },

    GET_ShareVideoList() {
      this.loading = true;
      API_ShareVideo.getPageList(this.params).then((response) => {
        if (response.data.length === 0 && response.page_no > 1) {
          this.params.page_no--;
          return this.GET_ShareVideoList();
        }

        this.loading = false;
        this.pageData = response;
      });
    },

    /** 编辑分享文案*/
    handleEditCombo(row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "shareVideoEdit",
        params: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
